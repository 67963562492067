import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import theme, { fullWidth } from "./theme"

export default (p: { style?: any }) => (
  <Footer style={p.style}>
    <FullWidth>
      <Column style={{ gridArea: "insta" }}>
        <a href="https://www.instagram.com/deepfakemoon/">Instagram</a>
      </Column>
      <Column style={{ gridArea: "twitter" }}>
        <a href="https://twitter.com/deepfakemoon/">Twitter</a>
      </Column>
      <Column style={{ gridArea: "email" }}>
        <a href="mailto:ineventof@moondisaster.org">Email</a>
      </Column>
    </FullWidth>
  </Footer>
)

const FullWidth = styled.div(fullWidth, {
  display: "grid",
  width: "250px !important",
  gridTemplateAreas: `
"insta twitter email"
  `,
  "div a": {
    fontSize: "1.0rem",
    textTransform: "uppercase",
    fontFamily: theme.fonts.header,
  },
})

const Footer = styled.footer({
  width: "100%",
  backgroundColor: theme.colors.dark,
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
  borderTop: `2px solid ${theme.colors.highlight}`,
  paddingTop: 15,
  paddingBottom: 15,
})

const Column = styled.div({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
})
