import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Hub from "../resources"
import {
  t,
  currentLanguage,
  Language,
  pathForLang,
  localizedSlugPattern,
} from "../i18n"

export default ({ data }) => (
  <>
    <Helmet>
      <meta property="og:url" content="https://moondisaster.org/resources" />
      <meta property="og:title" content={t("resources.meta.title")} />
      <meta
        property="og:description"
        content={t("resources.meta.description")}
      />
      <meta
        property="og:image:secure_url"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta
        property="og:image:url"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta
        property="og:image"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={t("resources.meta.imageAlt")} />
      <meta
        property="article:author:name"
        content={t("resources.meta.authorName")}
      />
      <meta name="twitter:title" content={t("resources.meta.title")} />
      <meta
        name="twitter:description"
        content={t("resources.meta.description")}
      />
      <meta name="twitter:creator" content={t("resources.meta.authorName")} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x854.jpg?p=1806"
      />
      <meta name="twitter:image:alt" content={t("resources.meta.imageAlt")} />
      <meta itemProp="name" content={t("resources.meta.title")} />
      <meta itemProp="description" content={t("resources.meta.description")} />
      <meta name="author" content={t("resources.meta.authorName")} />
      <meta name="description" content={t("resources.meta.description")} />
      <meta
        name="thumbnail"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
    </Helmet>
    <Hub
      data={data.allWpPost.nodes
        .filter((post) => post.slug.match(localizedSlugPattern))
        .map((post) => ({ ...post, url: pathForLang(post.url) }))}
    />
  </>
)

export const query = graphql`
  query {
    allWpPost(
      filter: {
        status: { eq: "publish" }
        categories: { nodes: { elemMatch: { name: { eq: "Articles" } } } }
      }
    ) {
      nodes {
        slug
        url: uri
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
        acf: postCustomFields {
          index
          mediaType
        }
      }
    }
  }
`
